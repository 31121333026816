<template>
	<div class="submit-btn u-m-b-15">
		<el-button type="primary" @click="submitInvoiceApply">提交</el-button>
		<div class="tips">请在消费结束2个月内自助申请开票!</div>
	</div>
	<!-- 表格 -->
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}" :header-cell-class-name="cellClass"  @selection-change="onSelectOrder" :row-key="getRowKey" >
		<el-table-column type="selection" :selectable="onSelectable" :reserve-selection="true" align="center"></el-table-column>
		<el-table-column prop="OrderNo" label="订单号" align="center"></el-table-column>
		<el-table-column prop="CanApply" label="申请状态" align="center">
			<template #default="scope">
				<span v-if="scope.row.CanApply=='售后周期内'" style="color:red;">售后周期内</span>
				<span v-if="scope.row.CanApply=='可申请'" style="color:green;">可申请</span>
				<span v-if="scope.row.CanApply=='已申请'" style="color:red;">已申请</span>
				<span v-if="scope.row.CanApply=='已过期'" style="color:#BBB;">已过期</span>
			</template>
		</el-table-column>
		<el-table-column prop="OrderTotalAmount" label="订单金额" align="center">
			<template #default="scope">
				<span>{{ scope.row.OrderTotalAmount.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="Effectivemoney" label="订单有效金额" align="center">
			<template #default="scope">
				<span>{{ scope.row.Effectivemoney.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="ServiceFee" label="服务费" align="center">
			<template #default="scope">
				<span>{{ scope.row.ServiceFee.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="InvoiceAmount" label="可开票额" align="center">
			<template #default="scope">
				<span>{{ scope.row.InvoiceAmount.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="SaleDate" label="订购日期" align="center">
			<template #default="scope">
				<span>{{moment(scope.row.SaleDate).format('YYYY-MM-DD')}}</span>
			</template>
		</el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>

	<invoice-apply-info ref="invoiceApplyInfoRef" @refresh-list="getApplyInvoiceList"></invoice-apply-info>
</template>

<script setup>
	import {reactive,ref,onMounted} from 'vue';
	import { ElMessage } from 'element-plus'
	import {webApplyInvoiceOrderList} from '@/api/user';
	import moment from 'moment'
	import invoiceApplyInfo from './invoiceApplyInfo.vue'
	onMounted(async () => {
		selectOrderList.value = []  // 清空选中的订单
		state.data.map(item => item.checked = false)
		await getApplyInvoiceList()

	})

	//数据源
	const state = reactive({
		params: {
			limit: {
				enable: true,
				conditions: {
					pageLength: 10,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})

	//添加隐藏表头全选按钮的属性名
	const cellClass = (row)=>{
		if(row.columnIndex===0){
			return 'disabledCheck'
		}
	}
	//为每一行指定唯一标识符
	const getRowKey = (row)=>{
		return row.Id
	}
	//是否可以勾选
	const onSelectable=(row,index)=>{
		return row.CanApply=='可申请'
	}
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		computeData()
	}
	/**
	 * 计算各种金额总和
	 * @param {无}
	 */
	const totalAmount = reactive({
		totalEffectivemoney:0,
		totalServiceFee:0,
		totalInvoiceAmount:0,
		orderIds: ''
	})

	const computeData = () => {
		// 先给为0，再计算
		Object.keys(totalAmount).forEach(key => totalAmount[key] = 0)
		for (let item of selectOrderList.value) {
			totalAmount.totalEffectivemoney += item.Effectivemoney	// 有效金额
			totalAmount.totalServiceFee += item.ServiceFee			// 服务费
			totalAmount.totalInvoiceAmount += item.InvoiceAmount			// 开票金额
		}
		const { totalEffectivemoney, totalServiceFee, totalInvoiceAmount} = totalAmount
	}

	/**
	 * 获取可申请发票订单列表
	 * @param {无}
	 */
	const getApplyInvoiceList = () => {
		state.loading = true
		return webApplyInvoiceOrderList(state.params).then(res => {
			const {data: {filterAmount,records}} = res
			state.data = records.map(item => {
				return {...item}
			})
			state.total = filterAmount
			state.loading = false
		})
	}


	// 选中的可申请发票订单
	const selectOrderList = ref([])

	/**
	 * 选择订单
	 * @param {无}
	 */
	const onSelectOrder = (rows) => {
		selectOrderList.value= rows.filter((item)=>item.Id);
		computeData()
	}
	/**
	 * 提交订单
	 * @param {无}
	 */
	const invoiceApplyInfoRef = ref(false);
	const submitInvoiceApply =() => {
		if (selectOrderList.value.length == 0) {
			ElMessage({ type: 'warning', message: '请选择申请开票的订单',duration:1000 })
			return
		}
		initData();
		totalAmount.orderIds = selectOrderList.value.map(item => item.Id).join(';')
		const params = {
			orderIds:totalAmount.orderIds,
			totalEffectivemoney:totalAmount.totalEffectivemoney,
			totalServiceFee:totalAmount.totalServiceFee,
			totalInvoiceAmount:totalAmount.totalInvoiceAmount
		}
		invoiceApplyInfoRef.value.onChangeVisible(params);
	}
	/**
	 * 改变页码
	 * @param {number} val
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getApplyInvoiceList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getApplyInvoiceList()
	};
</script>

<style lang="scss" scoped>
	.submit-btn{display:flex;justify-content:space-between;}
	.submit-btn div{margin-left:30px;}
	.tips{
		font-size:18px;
		color:red;
		text-align: center;
		font-weight: bold;
	}
	//隐藏表头全选按钮
	::v-deep .disabledCheck .cell {
	    visibility: hidden;
	  }
</style>

