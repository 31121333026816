<template>
  <div class="product-table u-m-t-5">
    <el-table
      ref="tableRef"
      :max-height="tableHeight"
      highlight-current-row
      :data="state.table_data"
      row-key="key"
      :expand-row-keys="state.row_keys"
      border
      empty-text="没有找到相关信息！"
      :indent="0"
      v-loading="state.loading"
      size="small"
      @sort-change="onSortChange"
    >
      <template v-for="(item, idx) in state.table_column" :key="idx">
        <el-table-column
          v-if="item.show_sale_type_list.length == 0 || item.show_sale_type_list.includes(props.sale_type)"
          :label="item.label"
          :align="item.align"
          :key="idx"
          :min-width="item.min_width"
          :header-align="item.header_align"
          :prop="item.prop"
          :sortable="item.sortable"
        >
          <template #default="scope">
            <template v-if="item.prop == 'PicPath'">
              <div class="product-img" v-if="scope.row.SaleId" @click="handleOpenProductDetail(scope.row)">
                <el-image style="width: 50px; height: 60px" :src="handlerImgUrl(scope.row.PicPath)"></el-image>
                <el-tag class="product-img__tag" size="small" type="danger" v-if="scope.row.HasPromotion > 0"
                  >惠</el-tag
                >
              </div>
              <el-image
                style="width: 50px; height: 60px"
                :src="handlerImgUrl(scope.row.PicPath)"
                preview-teleported
                :preview-src-list="[handlerImgUrl(scope.row.PicPath)]"
                hide-on-click-modal
                v-else
              ></el-image>
            </template>
            <template v-else-if="item.prop == 'Name'">
              <div class="table-flex u-flex-wrap" :class="{ 'product-name': !scope.row.Id }">
                <i
                  class="iconfont icon-aixin"
                  :style="`color: ${scope.row.IsLove == 1 ? '#FE5BA3' : '#CCCCCC'}`"
                  @click="onClickLove(scope.row)"
                  v-if="false && scope.row.Id"
                ></i>
                <span v-if="scope.row.Id">{{ scope.row.Brand }}</span>
                <span style="margin: 0 10px">{{ scope.row.Name }}</span>
                <span>{{ scope.row.Grade }}</span>
              </div>
              <!-- 三级定价 -->
              <div v-if="scope.row.GradePrice">
                <div v-if="isGradePrice(scope.row.GradePrice)">量大优惠：</div>
                <div v-for="n in [1, 2, 3]" :key="n">
                  <div
                    style="text-indent: 24px"
                    v-if="scope.row.GradePrice[`price${n}Enable`] == 1 && scope.row.GradePrice[`priceMinNum${n}`]"
                  >
                    ￥{{ scope.row.GradePrice[`price${n}`] }}
                    <span v-if="scope.row.GradePrice[`priceMinNum${n}`]">
                      ({{ scope.row.GradePrice[`priceMinNum${n}`] }}-{{ scope.row.GradePrice[`priceMaxNum${n}`]
                      }}{{ scope.row.Unit }})
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="item.prop == 'Price'">
              <div v-if="!scope.row.Id">{{
                scope.row.MinPrice == scope.row.MaxPrice
                  ? scope.row.MaxPrice
                  : `${scope.row.MinPrice}-${scope.row.MaxPrice}`
              }}</div>
              <div v-else>{{ scope.row.Price }}</div>
            </template>
            <template v-else-if="item.prop == 'Order'">
              <div v-if="!scope.row.Id">
                <loading v-if="scope.row.loading" />
                <el-button
                  type="primary"
                  style="color: white; background-color: green; padding: 0.03rem !important"
                  size="large"
                  link
                  @click="handleEnterOrder(scope.row)"
                  v-else
                  >进入订购</el-button
                >
              </div>
              <span class="table-flex u-flex-around u-flex-wrap" v-else>
                <el-button type="primary" size="small" @click="handleOrderProduct(scope.row)">订购</el-button>
                <el-button
                  type="primary"
                  style="margin-left: 10px !important"
                  size="small"
                  v-if="sale_type !== 7"
                  @click="handleAddCart(scope.row, scope.$index)"
                  >加入购物车</el-button
                >
              </span>
            </template>
            <template v-else-if="item.prop == 'BidPrice'">
              <el-input style="width: 80%; height: 25px" v-model="scope.row.orderPrice" v-if="scope.row.Id" />
              <!-- <el-input-number style="width: 70px" size="small" v-model="scope.row.orderPrice" :min="0" :precision="2" v-if="scope.row.Id" /> -->
              <div v-else>---</div>
            </template>
            <template v-else-if="item.prop == 'BidCount'">
              <el-input-number
                style="width: 80%"
                size="small"
                :min="0"
                :precision="0"
                v-model="scope.row.orderCount"
                v-if="scope.row.Id"
              />
              <div v-else>---</div>
            </template>
            <template v-else-if="item.prop == 'Bid'">
              <!-- <div v-if="!scope.row.Id"> -->
              <!--   <loading v-if="scope.row.loading" /> -->
              <!--   <el-button type="primary" size="small" link @click="handleEnterBid(scope.row)" v-else>进入竞购</el-button> -->
              <!-- </div> -->
              <span class="table-flex u-flex-around u-flex-wrap">
                <el-button type="primary" size="small" @click="handleBidProduct(scope.row)">竞购</el-button>
                <el-button
                  type="primary"
                  size="small"
                  style="margin-left: 10px !important"
                  @click="handleOrderProductHalfPrice(scope.row)"
                  v-if="isCanHalfPrice(scope.row.FloorPrice)"
                  >订购</el-button
                >
              </span>
            </template>
            <template v-else-if="item.prop == 'OrderCount'">
              <el-input-number
                style="width: 80%"
                size="small"
                :min="0"
                v-model="scope.row.orderCount"
                v-if="scope.row.Id"
              ></el-input-number>
              <div v-else>---</div>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div class="u-pagination" style="margin-top: 5px">
      <el-pagination
        background
        :current-page="state.limit.conditions.page_start"
        :page-size="state.limit.conditions.page_length"
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :total="state.total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>

    <!-- 查看产品详情 -->
    <productDetail ref="productDetailRef"></productDetail>
    <!-- 充值 -->
    <rechargeModal ref="rechargeRef"></rechargeModal>
  </div>
</template>

<script setup>
import {
  createAuctionOrder,
  createComingOrder,
  createCurrentOrder,
  doPresale,
  findAggregationPresale,
  findInfoPresale,
  productSaleListBySaleType,
  productSaleSumListBySaleType
} from '@/api/productSale';
import { handlerImgUrl, withDefaultsParams } from '@/common';
import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import productDetail from '@/components/product-detail/index.vue';
import rechargeModal from '@/components/recharge-modal/index.vue';
import loading from '@/components/loading/index.vue';
import { userStore } from '@/store/user';
import { SALE_TYPE } from '@/common/const';
import moment from 'moment';
import router from '@/router';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import { addShopCart } from '@/api/cart';
import { startLoading } from '@/common';
import { handleAnnualFee } from '@/api/user';

const tableHeight = ref(window.innerHeight - 230);

const productDetailRef = ref();
const rechargeRef = ref();

const userInfo = userStore();

const props = defineProps({
  keywords: {
    type: String,
    default: ''
  },
  product_name: {
    type: String,
    default: ''
  },
  is_new: {
    type: Number,
    default: 0
  },
  brand_type: {
    type: Number,
    default: 0
  },
  brand: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  grade: {
    type: String,
    default: ''
  },
  main_category: {
    type: String,
    default: ''
  },
  sub_category: {
    type: String,
    default: ''
  },
  is_my_love: {
    type: Number,
    default: 0
  },
  sort: {
    type: Number,
    default: 0
  },
  price_sort: {
    type: Number,
    default: 0
  },
  timely_count_sort: {
    type: Number,
    default: 0
  },
  sale_count_sort: {
    type: Number,
    default: 0
  },
  sale_type: {
    type: Number,
    default: SALE_TYPE.TODAY
  },
  brand_code: {
    type: String,
    default: ''
  },
  sale_date: {
    type: String,
    default: moment().format('YYYY-MM-DD')
  },
  label: {
    type: String,
    default: ''
  }
});

const state = reactive({
  row_keys: [],
  loading: false,
  total: 0,
  limit: {
    conditions: {
      page_start: 1,
      page_length: 15
    }
  },
  table_data: [],
  table_column: [
    {
      label: '图',
      prop: 'PicPath',
      align: 'center',
      min_width: '50',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: []
    },
    {
      label: '品名',
      prop: 'Name',
      align: 'center',
      min_width: '100',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: []
    },
    {
      label: '颜色',
      prop: 'Color',
      align: 'center',
      min_width: '50',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: []
    },
    {
      label: '长度',
      prop: 'Length',
      align: 'center',
      min_width: '50',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: []
    },
    {
      label: '包装',
      prop: 'PackCount',
      align: 'center',
      min_width: '50',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: []
    },
    {
      label: '单价',
      prop: 'Price',
      align: 'center',
      min_width: '50',
      sortable: props.sale_type != 2 ? true : false,
      header_align: 'center',
      show_sale_type_list: []
    },
    {
      label: '可售量',
      prop: 'TimelyCount',
      align: 'center',
      min_width: '50',
      sortable: true,
      header_align: 'center',
      show_sale_type_list: []
    },
    {
      label: '底价',
      prop: 'FloorPrice',
      align: 'center',
      min_width: '50',
      sortable: props.sale_type == 2 ? true : false,
      header_align: 'center',
      show_sale_type_list: [2]
    },
    {
      label: '出价',
      prop: 'BidPrice',
      align: 'center',
      min_width: '50',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: [2]
    },
    {
      label: '订购量',
      prop: 'OrderCount',
      align: 'center',
      min_width: '90',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: [1, 7, 3]
    },
    {
      label: '订购/加购',
      prop: 'Order',
      align: 'center',
      min_width: '70',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: [1, 7, 3]
    },
    {
      label: '竞购量',
      prop: 'BidCount',
      align: 'center',
      min_width: '70',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: [2]
    },
    {
      label: '竞购',
      prop: 'Bid',
      align: 'center',
      min_width: '70',
      sortable: false,
      header_align: 'center',
      show_sale_type_list: [2]
    }
  ]
});

const tableRef = ref(null);

const emit = defineEmits(['updateSort']);

onMounted(() => {
  refreshProductList();
});

watch(
  () => props,
  (newValue, oldValue) => {
    console.log('props', newValue, oldValue);
    if (newValue.sale_type != oldValue.sale_type) {
      console.log('sdfsdfsdfsd');
      tableRef.value.clearSort();
    }

    refreshProductList();
  },
  { deep: true }
);

// 三级定价是否全部有值
const isGradePrice = computed(() => {
  return function (str) {
    let flag = false;
    for (let i = 1; i <= 3; i++) {
      if (!str[`priceMaxNum${i}`] && !str[`priceMinNum${i}`]) continue;
      flag = true;
    }
    return flag;
  };
});

function onSortChange(event) {
  console.log('sort', event);
  const temp = {
    timely_count_sort: 0,
    price_sort: 0,
    sale_count_sort: 0
  };

  if (event.prop == 'TimelyCount') {
    if (event.order == 'descending') {
      temp.timely_count_sort = 1;
    } else if (event.order == 'ascending') {
      temp.timely_count_sort = 2;
    } else {
      temp.timely_count_sort = 0;
    }
  }

  if (event.prop == 'Price') {
    if (event.order == 'descending') {
      temp.price_sort = 1;
    } else if (event.order == 'ascending') {
      temp.price_sort = 2;
    } else {
      temp.price_sort = 0;
    }
  }

  if (event.prop == 'OrderCount') {
    if (event.order == 'descending') {
      temp.sale_count_sort = 1;
    } else if (event.order == 'ascending') {
      temp.sale_count_sort = 2;
    } else {
      temp.sale_count_sort = 0;
    }
  }

  emit('updateSort', temp);
}

/**
 * 获取产品聚合列表参数
 */
const productAggregationListParams = withDefaultsParams({
  keyword: props.keywords,
  productName: props.product_name,
  isNew: props.is_new,
  brandType: props.brand_type,
  brand: props.brand,
  color: props.color,
  grade: props.grade,
  tagId: props.label,
  mainCategory: props.main_category,
  subCategory: props.sub_category,
  isMyLove: props.is_my_love,
  sort: props.sort,
  priceSort: props.price_sort,
  timelyCountSort: props.timely_count_sort,
  saleType: props.sale_type,
  limit: {
    enable: true,
    conditions: {
      pageLength: state.limit.conditions.page_length,
      pageStart: state.limit.conditions.page_start
    }
  }
});

/**
 * 获取产品聚合列表
 */
const getProductAggregationList = productAggregationListParams(async query => {
  startLoading(async () => {
    const {
      code,
      data: { filterAmount, records }
    } = await productSaleSumListBySaleType(query);
    if (code == 0) {
      state.table_data = records.map(item => {
        return {
          ...item,
          key: item.Name + item.MainCategory + item.SubCategory + item.Brand + item.Grade,
          loading: false
        };
      });
      state.total = filterAmount;
    }
  });
});

/**
 * 获取具体的产品列表
 */
const productSaleListBySaleTypeParams = withDefaultsParams({
  keyword: props.keywords,
  productName: props.product_name,
  isNew: props.is_new,
  brandType: props.brand_type,
  brand: props.brand,
  color: props.color,
  grade: props.grade,
  tagId: props.tag_id,
  mainCategory: props.main_category,
  subCategory: props.sub_category,
  isMyLove: props.is_my_love,
  sort: props.sort,
  priceSort: props.price_sort,
  timelyCountSort: props.timely_count_sort,
  saleType: props.sale_type,
  limit: {
    enable: true,
    conditions: {
      pageLength: state.limit.conditions.page_length,
      pageStart: state.limit.conditions.page_start
    }
  }
});

/**
 * 获取产品列表
 */
const getProductSaleListBySaleType = productSaleListBySaleTypeParams(async (query, rowKey = 0) => {
  const idx = state.table_data.findIndex(item => item.key === rowKey);
  const idx_row = state.row_keys.findIndex(key => key === rowKey);
  if (idx_row !== -1) {
    delete state.table_data[idx].children;
    state.row_keys.splice(idx_row, 1);
    return;
  }
  if (idx == -1) return;

  state.table_data[idx].loading = true;

  const {
    code,
    data: { records }
  } = await productSaleListBySaleType(query);
  if (code == 0) {
    state.table_data[idx].children = records.map(item => {
      return {
        ...item,
        key: item.Name + item.MainCategory + item.SubCategory + item.Brand + item.Grade + item.Id,
        orderCount: null,
        orderPrice: 0,
        GradePrice: item.GradePrice ? JSON.parse(item.GradePrice) : ''
      };
    });
    state.table_data[idx].loading = false;
    state.row_keys.push(rowKey);
  } else {
    ElMessage('获取子列表失败');
  }
});

/**
 * 获取竞购列表
 */
const bidProductListDefaultParams = withDefaultsParams({
  keyword: props.keywords,
  productName: props.product_name,
  isNew: props.is_new,
  brandType: props.brand_type,
  brand: props.brand,
  color: props.color,
  grade: props.grade,
  tagId: props.label,
  mainCategory: props.main_category,
  subCategory: props.sub_category,
  isMyLove: props.is_my_love,
  sort: props.sort,
  priceSort: props.price_sort,
  timelyCountSort: props.timely_count_sort,
  saleType: SALE_TYPE.BID,
  limit: {
    enable: true,
    conditions: {
      pageLength: state.limit.conditions.page_length,
      pageStart: state.limit.conditions.page_start
    }
  }
});

/**
 * 获取竞购产品列表
 */
const getBidProductList = bidProductListDefaultParams(async query => {
  startLoading(async () => {
    const {
      code,
      data: { records, filterAmount }
    } = await productSaleListBySaleType(query);
    if (code == 0) {
      state.table_data = records.map(item => {
        return {
          ...item,
          key: item.Name + item.MainCategory + item.SubCategory + item.Brand + item.Grade + item.Id,
          loading: false
        };
      });
      state.total = filterAmount;
    } else {
      console.log('获取子列表失败');
    }
  });
});

/**
 * 获取晚场产品聚合列表参数
 */
const presaleAggregationParams = withDefaultsParams({
  sale_date: props.sale_date,
  x_pagesize: state.limit.conditions.page_length,
  x_page: state.limit.conditions.page_start,
  main_category: props.main_category,
  sub_category: props.sub_category,
  keywords: props.keywords,
  brand_code: props.brand_code,
  color: props.color,
  price_sort: props.price_sort,
  timely_count_sort: props.timely_count_sort,
  sale_count_sort: props.sale_count_sort,
  grade: props.grade
});

/**
 * 获取晚场聚合产品列表
 */
const getPresaleAggregationProductList = presaleAggregationParams(async params => {
  console.log(params);
  startLoading(async () => {
    const { data, headers } = await findAggregationPresale(params);
    console.log('晚场聚合列表', data, headers);
    state.table_data = data.map(item => {
      return {
        Color: item.color,
        Grade: item.grade,
        Length: item.length,
        MainCategory: item.main_category,
        SubCategory: item.sub_category,
        MaxPrice: item.max_price,
        MinPrice: item.min_price,
        PackCount: item.pack_count,
        PicPath: item.pic_path,
        Name: item.product_name,
        SaleCount: item.sale_count,
        SupplyCount: item.supply_count,
        TimelyCount: item.timely_count,
        Unit: item.unit,
        key: item.product_name + item.main_category + item.sub_category + params.brand_code + item.grade,
        loading: false
      };
    });
    state.total = Number(headers['x-total']);
  });
});

/**
 * 晚场详细列表请求参数
 */
const presaleInfoParams = withDefaultsParams({
  sale_date: props.sale_date,
  main_category: props.main_category,
  sub_category: props.sub_category,
  product_name: props.product_name,
  grade: props.grade,
  keywords: props.keywords,
  price_sort: props.price_sort,
  timely_count_sort: props.timely_count_sort,
  sale_count_sort: props.sale_count_sort,
  brand_code: props.brand_code,
  color: props.color
});

const getPresaleInfoList = presaleInfoParams(async (query, rowKey = 0) => {
  const idx = state.table_data.findIndex(item => item.key === rowKey);
  const idx_row = state.row_keys.findIndex(key => key === rowKey);
  if (idx_row !== -1) {
    delete state.table_data[idx].children;
    state.row_keys.splice(idx_row, 1);
    return;
  }
  if (idx == -1) return;

  state.table_data[idx].loading = true;
  try {
    const { headers, data } = await findInfoPresale(query);
    console.log('aaaaaa', data);
    state.table_data[idx].children = data.map(item => {
      return {
        Id: item.id,
        SaleId: item.id,
        Bending: item.bending,
        Blade: item.blade,
        BrandCode: item.brand_code,
        Bud: item.bud,
        Damage: item.damage,
        Diseased: item.diseased,
        Openness: item.openness,
        Stalk: item.stalk,
        Color: item.color,
        Grade: item.grade,
        Length: item.length,
        SubCategory: item.sub_category,
        PackCount: item.pack_count,
        PicPath: item.pic_path,
        Name: item.product_name,
        SaleCount: item.sale_count,
        SupplyCount: item.supply_count,
        TimelyCount: item.timely_count,
        Price: item.sale_price,
        Brand: item.brand,
        Unit: item.unit,
        key: item.product_name + query.main_category + query.sub_category + item.brand + item.grade + item.id,
        orderCount: 0,
        orderPrice: 0
      };
    });
    state.table_data[idx].loading = false;
    state.row_keys.push(rowKey);
    console.log('fffffffff', state);
  } catch (error) {
    console.log('获取子列表失败', error);
  }
});

/**
 * 刷新列表
 */
function refreshProductList() {
  switch (props.sale_type) {
    case SALE_TYPE.TODAY:
      getProductAggregationList({
        keyword: props.keywords,
        productName: props.product_name,
        isNew: props.is_new,
        brandType: props.brand_type,
        brand: props.brand,
        color: props.color,
        grade: props.grade,
        tagId: props.label,
        mainCategory: props.main_category,
        subCategory: props.sub_category,
        isMyLove: props.is_my_love,
        sort: props.price_sort || props.timely_count_sort || props.sale_count_sort ? 1 : 0,
        priceSort: props.price_sort,
        timelyCountSort: props.timely_count_sort,
        saleType: SALE_TYPE.TODAY
      });
      break;
    case SALE_TYPE.TOMMOROW:
      getProductAggregationList({
        keyword: props.keywords,
        productName: props.product_name,
        isNew: props.is_new,
        brandType: props.brand_type,
        brand: props.brand,
        color: props.color,
        grade: props.grade,
        tagId: props.label,
        mainCategory: props.main_category,
        subCategory: props.sub_category,
        isMyLove: props.is_my_love,
        sort: props.price_sort || props.timely_count_sort || props.sale_count_sort ? 1 : 0,
        priceSort: props.price_sort,
        timelyCountSort: props.timely_count_sort,
        saleType: SALE_TYPE.TOMMOROW
      });
      break;
    case SALE_TYPE.BID:
      getBidProductList({
        keyword: props.keywords,
        productName: props.product_name,
        isNew: props.is_new,
        brandType: props.brand_type,
        brand: props.brand,
        color: props.color,
        grade: props.grade,
        tagId: props.label,
        mainCategory: props.main_category,
        subCategory: props.sub_category,
        isMyLove: props.is_my_love,
        sort: props.price_sort || props.timely_count_sort || props.sale_count_sort ? 1 : 0,
        priceSort: props.price_sort,
        timelyCountSort: props.timely_count_sort,
        saleType: SALE_TYPE.BID
      });
      break;
    case SALE_TYPE.AFTERNOON:
      getPresaleAggregationProductList({
        sale_date: props.sale_date,
        x_pagesize: state.limit.conditions.page_length,
        x_page: state.limit.conditions.page_start - 1,
        main_category: props.main_category,
        sub_category: props.sub_category,
        keywords: props.keywords,
        brand_code: props.brand_code,
        color: props.color,
        price_sort: props.price_sort,
        timely_count_sort: props.timely_count_sort,
        sale_count_sort: props.sale_count_sort,
        grade: props.grade
      });
      break;
    default:
      break;
  }
}

/**
 * 晚场订购
 */
const doOrderProductAfternoon = withDefaultsParams({
  id: '',
  order_count: 0,
  device_type: 1
})(async (params, fn) => {
  startLoading(
    async () => {
      const res = await doPresale(params);
      console.log('晚场订购', res);
      ElMessage({
        type: 'success',
        message: '订购成功!'
      });
      fn & fn();
    },
    { text: '订购中...' }
  );
});

/**
 * 预售订购
 */
const orderProductPresale = withDefaultsParams({})(async (params, cb) => {
  // 预售订购
  if (props.sale_type == 6) {
    startLoading(async () => {
      const { code } = await createBookingOrder({ ...params });
      isMemberExpire(code);
      isAddressApprovedOrAdd(code);
      if (code == 0) {
        ElMessage.success('订购成功');
        cb && cb();
      } else {
        ElMessage.warning('订购失败');
      }
    });
  }
});

/**
 * 明天、后天、大后天订购
 * @param {object} event
 */
const doOrderProductTommorow = withDefaultsParams({})(async (params, cb) => {
  startLoading(async () => {
    const data = await createComingOrder({ ...params });
    // isTomorrowPreSale(data, createComingOrder, { ...params })
    if (data.code == 0) {
      ElMessage.success('订购成功');
      userInfo.getMemberInfo();
      cb && cb();
    } else {
      orderResultCodeParse(data.code);
    }
  });
});

/**
 * 现售订购
 * @param {object} event
 */
const doOrderProductToday = withDefaultsParams({
  saleId: '',
  orderCount: 0,
  buyType: 1
})(async (params, cb) => {
  startLoading(
    async () => {
      const { code } = await createCurrentOrder(params);
      if (code == 0) {
        ElMessage.success('订购成功');
        cb && cb();
      } else {
        orderResultCodeParse(code);
      }
    },
    { text: '正在提交订单，请稍后...' }
  );
});

/**
 * 竞购默认参数\
 */
const bidDefaultParams = withDefaultsParams({
  saleId: '',
  orderCount: 0,
  orderPrice: 0,
  buyType: 1
});

/**
 * 竞购
 */
const doBidProduct = bidDefaultParams(async params => {
  startLoading(async () => {
    const { data } = await createAuctionOrder(params);
    orderResultCodeParse(data.code);
    if (data.code == 0) {
      userInfo.getMemberInfo();
      ElMessage({
        type: 'success',
        message: '竞购成功'
      });
    }
  });
});

/**
 * 订购失败结果码处理
 */
const orderResultCodeParse = code => {
  switch (code) {
    case 5:
      const flag = userInfo.addressList.length > 0;
      const title = flag
        ? `您的收货地址仍在审核中，请找客户经理加紧审核。如紧急请拨打：（${userInfo.customerManager.tel}）`
        : '您还未添加收货地址';
      ElMessageBox.alert(title, '提示', {
        type: 'warning',
        confirmButtonText: flag ? '确定' : '去添加'
      }).then(async () => {
        if (!flag) {
          router.push('/index/personalCenter/shipAddress');
        }
      });
      break;
    case 6:
      ElMessageBox.confirm('试用期已结束，需交200元会员年费(年费不退)成为正式会员，有效期一年，请充值', '温馨提示', {
        type: 'warning',
        cancelButtonText: '否',
        confirmButtonText: '去充值'
      }).then(() => {
        // 跳转到充值页面
        rechargeRef.value.onChangeVisible();
      });
      break;
    case 7:
      ElMessageBox.confirm(
        '试用期已结束，需交200元会员年费(年费不退)成为正式会员，有效期一年，是否扣费？',
        '温馨提示',
        {
          type: 'warning',
          cancelButtonText: '否',
          confirmButtonText: '是'
        }
      ).then(() => {
        //调用扣费接口
        handleAnnualFee({ memberCode: userInfo.memberInfo.memberCode });
        ElMessage.success('操作成功，祝您购物愉快！');
      });
      break;
    case 8:
      ElMessageBox.confirm('余额不足，现有' + userInfo.memberInfo.monetary + '元，请充值', '温馨提示', {
        type: 'warning',
        cancelButtonText: '否',
        confirmButtonText: '去充值'
      }).then(() => {
        // 跳转到充值页面
        rechargeRef.value.onChangeVisible();
      });
      break;
    case 9:
      ElMessageBox.confirm('会员年费已到期，需交200元年费(年费不退)续费，是否扣费？', '温馨提示', {
        type: 'warning',
        cancelButtonText: '否',
        confirmButtonText: '是'
      }).then(() => {
        //调用扣费接口
        handleAnnualFee({ memberCode: userInfo.memberInfo.memberCode });
        ElMessage.success('操作成功，祝您购物愉快！');
      });
      break;
    default:
      break;
  }
};

//竞购区是否可以半价购买
const isCanHalfPrice = floorPrice => {
  return userInfo.memberDetailInfo.memberType == '城市战略合作伙伴' && floorPrice <= 0;
};

const doHalfOrder = async (params, saleType, fn) => {
  if (saleType != 2) return ElMessage({ type: 'warning', message: '参与竞购的产品才可以半价购买。', duration: 2000 });
  startLoading(async () => {
    const { code } = await createCurrentOrder(params);
    if (code == 0) {
      userInfo.getMemberInfo();
      ElMessage.success('订购成功');
      fn && fn();
    } else {
      orderResultCodeParse(code);
    }
  });
};

//城市战略合作伙伴半价订购
const handleOrderProductHalfPrice = async event => {
  if (event.orderCount == null || event.orderCount == 0)
    return ElMessage({ type: 'warning', message: '订购量不能等于零', duration: 2000 });
  if (event.orderCount > event.TimelyCount)
    return ElMessage({ type: 'warning', message: '订购量不可以超过可售量', duration: 2000 });
  if (moment().format('hour') < 11 && state.sale_type == 2)
    return ElMessage({ type: 'warning', message: '订购时间为11:00-12:00', duration: 2000 });
  if (moment().format('hour') > 11 && state.sale_type == 2)
    return ElMessage({ type: 'warning', message: '今天交易已结束。', duration: 2000 });

  const params = {
    saleId: event.SaleId,
    orderCount: event.orderCount,
    buyType: 1
  };
  doHalfOrder(params, props.sale_type, () => {
    // 刷新余额
    userInfo.getMemberInfo();
    // 刷新可售量
    event.TimelyCount -= event.orderCount;
  });
};

/**
 * 加入购物车
 * @param {object} event
 * @param {number} index
 */
const doAddCart = withDefaultsParams({
  saleId: '',
  orderCount: 0,
  saleType: props.sale_type,
  saleDate: props.sale_date,
  buyType: 1
})(async params => {
  startLoading(async () => {
    const { code } = await addShopCart(params);
    orderResultCodeParse(code);
    if (code == 0) {
      ElMessage.success('添加成功');
      cartInfo.getCartList(); // 刷新购物车列表
    } else {
      // getSupplierProductList()
    }
  });
});

/**
 * 竞购商品
 */
function handleBidProduct(event) {
  if (event.orderCount == null || event.orderCount == 0) {
    ElMessage({ type: 'warning', message: '竞购量不能等于零', duration: 2000 });
    return;
  }
  if (event.orderCount > event.TimelyCount) {
    ElMessage({ type: 'warning', message: '订购量不可以超过可售量', duration: 2000 });
    return;
  }
  if (event.orderPrice >= event.Price) {
    ElMessage({ type: 'warning', message: '出价不能大于正价', duration: 2000 });
    return;
  }
  if (event.orderPrice < event.FloorPrice) {
    ElMessage({ type: 'warning', message: '出价不能低于底价', duration: 2000 });
    return;
  }
  doBidProduct({
    saleId: event.SaleId,
    orderCount: event.orderCount,
    orderPrice: event.orderPrice
  });
  event.orderCount = null;
  event.orderPrice = 0;
}

/**
 * 加入购物车
 */
function handleAddCart(event) {
  if (event.orderCount == null || event.orderCount == 0) {
    ElMessage({ type: 'warning', message: '订购量不能等于零', duration: 2000 });
    return;
  }
  if (event.orderCount > event.TimelyCount) {
    ElMessage({ type: 'warning', message: '订购量不可以超过可售量', duration: 2000 });
    return;
  }
  doAddCart({
    saleId: event.SaleId || event.saleId,
    orderCount: event.orderCount,
    saleType: props.sale_type,
    saleDate: props.sale_date
  });
}

function handleOrderProduct(event) {
  console.log('订购信息', event);
  if (event.orderCount == null || event.orderCount == 0)
    return ElMessage({ type: 'warning', message: '订购量不能等于零', duration: 2000 });
  if (event.orderCount > event.TimelyCount)
    return ElMessage({ type: 'warning', message: '订购量不可以超过可售量', duration: 2000 });
  if (moment().hour() > 11 && props.sale_type == SALE_TYPE.TODAY) {
    // ElMessageBox.confirm('今天的交易已结束，是否切换至明天区下单？', '温馨提示', {
    //   type: 'warning',
    //   cancelButtonText: '否',
    //   confirmButtonText: '去明天区'
    // }).then(async () => {
    //   // 跳转到明天区
    //   emit("update:sale_type", SALE_TYPE.TOMMOROW)
    // });
    return ElMessage({ type: 'warning', message: '今天的交易已结束', duration: 2000 });
  }

  if (props.sale_type == SALE_TYPE.TODAY) {
    doOrderProductToday(
      {
        saleId: event.SaleId || event.saleId,
        orderCount: event.orderCount
      },
      () => {
        console.log('更新信息');
        // 刷新余额
        userInfo.getMemberInfo();
        // 刷新可售量
        event.TimelyCount -= event.orderCount;
      }
    );
  } else if (props.sale_type == SALE_TYPE.AFTERNOON) {
    doOrderProductAfternoon(
      {
        id: event.Id,
        order_count: event.orderCount
      },
      () => {
        console.log('更新信息');
        // 刷新余额
        userInfo.getMemberInfo();
        // 刷新可售量
        event.TimelyCount -= event.orderCount;
      }
    );
  } else if (
    [SALE_TYPE.AFTER_ONE_TOMMOROW, SALE_TYPE.TOMMOROW, SALE_TYPE.AFTER_TWO_TOMMOROW].includes(props.sale_type)
  ) {
    doOrderProductTommorow(
      {
        saleId: event.saleId || event.SaleId,
        orderCount: event.orderCount
      },
      () => {
        console.log('更新信息');
        // 刷新余额
        userInfo.getMemberInfo();
        // 刷新可售量
        event.TimelyCount -= event.orderCount;
      }
    );
  }
}

/**
 * 进入竞拍
 */
function handleEnterAuction(event) {
  if (props.sale_type == SALE_TYPE.TODAY) {
    return ElMessage({ type: 'warning', message: '今天的交易已结束', duration: 2000 });
  }
}

/**
 * 进入竞购
 */
function handleEnterBid(event) {
  getProductSaleListBySaleType(
    {
      productName: event.Name,
      mainCategory: event.MainCategory,
      subCategory: event.SubCategory
    },
    event.key
  );
}

/**
 * 进入订购
 */
function handleEnterOrder(event) {
  if ([SALE_TYPE.TODAY, SALE_TYPE.TOMMOROW].includes(props.sale_type)) {
    getProductSaleListBySaleType(
      {
        productName: event.Name,
        mainCategory: event.MainCategory,
        subCategory: event.SubCategory,
        grade: event.Grade,
        saleType: props.sale_type
      },
      event.key
    );
  } else if (props.sale_type == SALE_TYPE.AFTERNOON) {
    getPresaleInfoList(
      {
        product_name: event.Name,
        main_category: event.MainCategory,
        sub_category: event.SubCategory,
        grade: event.Grade,
        brand_code: props.brand_code
      },
      event.key
    );
  }
}

/**
 * 打开产品详情
 */
function handleOpenProductDetail(productInfo) {
  productDetailRef.value.onChangeVisible(productInfo, false);
}

function handleCurrentChange(event) {
  console.log(event);
  state.limit.conditions.page_start = event;
  if (props.sale_type == SALE_TYPE.AFTERNOON) {
    getPresaleAggregationProductList({
      x_page: state.limit.conditions.page_start - 1,
      sale_date: props.sale_date,
      x_pagesize: state.limit.conditions.page_length,
      main_category: props.main_category,
      sub_category: props.sub_category,
      keywords: props.keywords,
      brand_code: props.brand_code,
      color: props.color,
      price_sort: props.price_sort,
      timely_count_sort: props.timely_count_sort,
      sale_count_sort: props.sale_count_sort,
      grade: props.grade
    });
  } else if (props.sale_type == SALE_TYPE.BID) {
    getBidProductList({
      keyword: props.keywords,
      productName: props.product_name,
      isNew: props.is_new,
      brandType: props.brand_type,
      brand: props.brand,
      color: props.color,
      grade: props.grade,
      tagId: props.label,
      mainCategory: props.main_category,
      subCategory: props.sub_category,
      isMyLove: props.is_my_love,
      sort: props.sort,
      priceSort: props.price_sort,
      timelyCountSort: props.timely_count_sort,
      saleType: SALE_TYPE.BID,
      limit: {
        enable: true,
        conditions: { pageStart: state.limit.conditions.page_start, pageLength: state.limit.conditions.page_length }
      }
    });
  } else {
    getProductAggregationList({
      keyword: props.keywords,
      productName: props.product_name,
      isNew: props.is_new,
      brandType: props.brand_type,
      brand: props.brand,
      color: props.color,
      grade: props.grade,
      tagId: props.label,
      mainCategory: props.main_category,
      subCategory: props.sub_category,
      isMyLove: props.is_my_love,
      sort: props.sort,
      priceSort: props.price_sort,
      timelyCountSort: props.timely_count_sort,
      saleType: props.sale_type,
      limit: {
        enable: true,
        conditions: { pageStart: state.limit.conditions.page_start, pageLength: state.limit.conditions.page_length }
      }
    });
  }
}

function handleSizeChange(event) {
  console.log(event);
  state.limit.conditions.page_start = event;
  if (props.sale_type == SALE_TYPE.AFTERNOON) {
    getPresaleAggregationProductList({
      x_page: state.limit.conditions.page_start - 1
    });
  } else {
    getProductAggregationList({
      limit: {
        enable: true,
        conditions: { pageStart: state.limit.conditions.page_start, pageLength: state.limit.conditions.page_length }
      }
    });
  }
}
</script>
