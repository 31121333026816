<template>
  <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="false"
    title="商品详情"
    align-center
    width="900"
    :draggable="true"
  >
    <div class="pro-details-wrap">
      <div class="activityInfo">
        <el-carousel
          :interval="5000"
          direction="vertical"
          indicator-position="none"
          v-if="state.product.salesPromotionList?.length > 0"
        >
          <el-carousel-item v-for="item in state.product.salesPromotionList" :key="item" name="index">
            <div class="activityInfo-name">
              {{ ACTIVITY_TYPE[item.promotionType].label }}: {{ item.salesPromotionName }}
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="pro-info-wrap">
        <!-- 广告图 -->
        <div class="pro-img-wrap">
          <el-carousel
            :interval="5000"
            height="400px"
            :autoplay="!state.product.productSmallImgList[initialIndex].isVideo"
            indicator-position="none"
            :initial-index="initialIndex"
            @change="initialIndex = $event"
          >
            <el-carousel-item v-for="item in state.product.productSmallImgList" :key="item" name="index">
              <video
                class="pro-big-img"
                style="object-fit: cover"
                controls
                :src="handlerImgUrl(item.path)"
                v-if="item.isVideo"
              ></video>
              <el-image class="pro-big-img" :src="handlerImgUrl(item.path)" v-else></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>

        <!-- 产品信息 -->
        <div class="pro-info-box">
          <div class="base-info">
            <div class="info-title">{{ state.product.brand + state.product.productName + state.product.grade }}</div>
            <div class="u-tr">
              <div class="u-td"><text class="u-lable">颜色：</text>{{ state.product.color }}</div>
            </div>
            <div class="u-tr">
              <div class="u-td"><text class="u-lable">装量：</text>{{ state.product.PackCount }}</div>
              <div class="u-td"><text class="u-lable">库存：</text>{{ state.product.timelyCount }}</div>
            </div>
            <div class="u-tr">
              <div class="u-td"><text class="u-lable">正价：</text>{{ state.product.price }}</div>
              <div class="u-td" v-if="state.product.isBid"
                ><text class="u-lable">底价：</text>{{ state.product.floorPrice }}</div
              >
            </div>
            <div class="u-tr">
              <div class="u-td"><text class="u-lable">已竞购次数：</text>{{ state.product.bidCount }}</div>
            </div>
            <div class="u-tr">
              <div class="u-td"><text class="u-lable">品牌正价率：</text>{{ state.product.positiveValenceRate }}</div>
              <div class="u-td"><text class="u-lable">品牌缺货率：</text>{{ state.product.stockoutRate }}</div>
              <div class="u-td"><text class="u-lable">品牌投诉率：</text>{{ state.product.complaintRate }}</div>
            </div>
            <div class="u-tr">
              <div class="u-td"
                ><text class="u-lable">单品正价率：</text>{{ state.product.productPositiveValenceRate }}</div
              >
              <div class="u-td"><text class="u-lable">单品缺货率：</text>{{ state.product.productStockoutRate }}</div>
              <div class="u-td"><text class="u-lable">单品投诉率：</text>{{ state.product.productComplaintRate }}</div>
            </div>
            <div class="selected-pro-info">
              <div><text class="u-lable">长度：</text>{{ state.product.length }}</div>
            </div>
            <div><text class="u-lable">备注：</text>{{ state.product.remark }}</div>
            <div><text class="u-lable">整扎标准：</text>{{ state.product.TieStandard }}</div>
          </div>
        </div>
      </div>

      <!-- 评价 -->
      <div class="evaluation-wrap">
        <div class="title">客户评价</div>
        <div class="evaluation-list" v-for="(item, index) in state.evaluateList" :key="index">
          <div class="time-box">
            <div
              >{{ item.EvaluateTime }}<span class="u-m-l-15">来自{{ item.MemberIP }}</span></div
            >
            <el-rate v-model="item.EvaluateRate" color="#FF6A00" allow-half size="large" disabled />
          </div>
          <div class="evaluation-imgs" v-if="item.EvaluateImg.length > 0">
            <el-image
              class="evaluation-img"
              v-for="(src, srcIndex) in item.EvaluateImg"
              :key="srcIndex"
              :src="src"
              :preview-src-list="item.EvaluateImg"
              :initial-index="srcIndex"
              preview-teleported
            ></el-image>
          </div>
          <div class="u-m-t-10">{{ item.EvaluateContent }}</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { getProductDetailWithCode, getProductEvaluateList } from '@/api/productSale';
import { ACTIVITY_TYPE } from '@/enums/product';
import { handlerImgUrl } from '@/common/index';

const initialIndex = ref(0);
const state = reactive({
  query: {},
  product: {
    salesPromotionList: []
  },
  evaluateList: []
});

/**
 * 弹窗
 * @param {object} event
 */
const dialogVisible = ref(false);
const onChangeVisible = async (event, needReq = true) => {
  console.log(event);
  if (needReq) {
    state.query = event;
    await getProDetail();
    await getEvaluateList();
  } else {
    state.product = event;
    state.product['productSmallImgList'] = [{ path: event.PicPath, isVideo: false }];
    state.product['timelyCount'] = event.TimelyCount;
    state.product['grade'] = event.Grade;
    state.product['brand'] = event.Brand;
    state.product['productName'] = event.Name;
    state.product['color'] = event.Color;
    state.product['PackCount'] = event.PackCount;
    state.product['timelyCount'] = event.TimelyCount;
    state.product['price'] = event.Price;
    state.product['floorPrice'] = 0;
    state.product['bidCount'] = 0;
    state.product['positiveValenceRate'] = '100%';
    state.product['stockoutRate'] = '0%';
    state.product['complaintRate'] = '0%';
    state.product['productPositiveValenceRate'] = '100%';
    state.product['productStockoutRate'] = '0%';
    state.product['productComplaintRate'] = '0%';
    state.product['length'] = event.Length;
    state.product['remark'] = '';
    state.product['TieStandard'] = '';
    state.product['evaluateList'] = [];
  }

  dialogVisible.value = true;
};

/**
 * 取产品详情
 * @param {无}
 */
const getProDetail = async () => {
  const {
    code,
    data: { productInfo }
  } = await getProductDetailWithCode({ code: state.query.Code });
  if (code == 0) {
    state.product = productInfo;
    state.product.productSmallImgList = state.product.productSmallImgList.map(item => {
      return { ...item, path: handlerImgUrl(item.path) };
    });
    if (productInfo.productSmallImgList.length == 0 && productInfo.picPath) {
      state.product.productSmallImgList.push({ path: handlerImgUrl(productInfo.picPath) });
    }
    if (productInfo.videoPath) {
      state.product.productSmallImgList.unshift({ path: productInfo.videoPath, isVideo: true });
    }
  }
};

/**
 * 取产品评价列表
 * @param {无}
 */
const getEvaluateList = async () => {
  const {
    data: { records }
  } = await getProductEvaluateList({ productCode: state.product.productCode });
  state.evaluateList = records.map(item => {
    return { ...item, EvaluateImg: item.EvaluateImg.split(',').map(src => handlerImgUrl(src)) };
  });
};

// 暴露给父组件
defineExpose({
  onChangeVisible
});
</script>

<style lang="scss" scoped>
.activityInfo {
  box-sizing: border-box;
  height: 40px;
  background-color: #fe5ba3;
  margin-bottom: 20px;
  padding: 0 10px;

  &-name {
    line-height: 40px;
    font-size: 16px;
    color: #fff;
  }
}

.pro-info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .pro-img-wrap {
    width: 300px;
    margin-right: 30px;

    .pro-big-img {
      width: 100%;
      height: 100%;
    }
  }

  .pro-info-box {
    box-shadow: 0px 0px 10px #cccccc;
    flex: 1;
    font-size: 22px;
    line-height: 40px;

    .base-info {
      padding: 15px;

      .info-title {
        font-weight: bold;
      }

      .u-tr {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .u-td {
          width: 50%;
        }
      }

      .selected-pro-info {
        margin: 15px 0px;
        padding: 15px 0px;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
      }
    }
  }
}

.evaluation-wrap {
  margin-top: 30px;
  padding: 20px 0px;

  .title {
    font-size: 26px;
    font-weight: bold;
    padding-top: 15px;
  }

  .evaluation-list {
    padding: 15px 0px;
    font-size: 18px;

    .time-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #999999;
    }

    .evaluation-imgs {
      display: grid;
      grid-template-columns: repeat(auto-fill, 100px);
      grid-gap: 5px;
    }

    .evaluation-img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
